<template>
  <!--MODAL COPY DAY-->
  <b-modal
    id="copy-period-modal"
    :title="$t('broadcast.copyPeriod')"
    :ok-disabled="$v.$invalid"
    :ok-title="$t('broadcast.copy')"
    :cancel-title="$t('table.cancel')"
    :busy="isModalBusy"
    @ok.prevent="showModalCopyPeriodConfirm"
    @show="clearData()"
  >
    <b-form-checkbox v-model="isFreeMode" name="check-button-free-mode" switch :disabled="isModalBusy">
      <abbr :title="$t('broadcast.dateRangesMustBeSame')"> {{ $t('broadcast.customPeriod') }} </abbr>
    </b-form-checkbox>
    <div class="gap-2 grid-col-2">
      <b-form-group :label="$t('channelModal.from')" label-for="copy-period-datepicker-buttons-from">
        <datepicker-wrapper
          id="copy-period-datepicker-buttons-from"
          v-model="copyPeriod.dateFrom"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          :disabled="isModalBusy"
          required
        />
      </b-form-group>
      <b-form-group :label="$t('channelModal.to')" label-for="copy-period-datepicker-buttons-from-end">
        <datepicker-wrapper
          id="copy-period-datepicker-buttons-from-end"
          v-model="copyPeriod.dateFromEnd"
          :min="minDateFromEnd"
          :initial-date="copyPeriod.dateFrom"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          :disabled="!isFreeMode || isModalBusy"
          required
        />
      </b-form-group>
    </div>
    <div class="gap-2 grid-col-2">
      <b-form-group :label="$t('channelModal.on')" label-for="copy-period-datepicker-buttons-to">
        <datepicker-wrapper
          id="copy-period-datepicker-buttons-to"
          v-model="copyPeriod.dateTo"
          :initial-date="copyPeriod.dateFrom"
          :date-disabled-fn="datesToDisabled"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          :disabled="isModalBusy"
          required
        />
      </b-form-group>
      <b-form-group :label="$t('channelModal.to')" label-for="copy-period-datepicker-buttons-to-end">
        <datepicker-wrapper
          id="copy-period-datepicker-buttons-to-end"
          v-model="dateToEnd"
          :date-disabled-fn="datesToDisabled"
          :min="minDateEndEnd"
          disabled
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          required
        />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { required, not, sameAs } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import convertDate from '@/filters/convertDate';

function isNotPeriodToInPeriodFrom() {
  //check for not overlapping periods
  const dateFromStart = new Date(this.copyPeriod.dateFrom);
  const dateFromEnd = new Date(this.copyPeriod.dateFromEnd);
  const dateToStart = new Date(this.copyPeriod.dateTo);
  const dateToEnd = new Date(this.dateToEnd);
  return !((dateToStart <= dateFromStart && dateToEnd >= dateFromStart) || (dateToStart >= dateFromStart && dateToStart <= dateFromEnd));
}

function isDateFromEndBiggerFrom() {
  return new Date(this.copyPeriod.dateFromEnd) > new Date(this.copyPeriod.dateFrom);
}
function isDateToEndBiggerTo() {
  return new Date(this.dateToEnd) > new Date(this.copyPeriod.dateTo);
}

function isPeriodsEqual() {
  if (!(this.copyPeriod.dateFrom && this.copyPeriod.dateFromEnd && this.copyPeriod.dateTo && this.dateToEnd)) return false;
  return new Date(this.copyPeriod.dateFromEnd) - new Date(this.copyPeriod.dateFrom) === new Date(this.dateToEnd) - new Date(this.copyPeriod.dateTo);
}

export default {
  components: { DatepickerWrapper },
  filters: { convertDate },
  props: {
    channel: {
      required: true,
      type: Object,
    },
  },
  // defineEmits<{
  //   (e: 'periodCopied'): void,
  // }>()
  data() {
    return {
      copyPeriod: {
        dateFrom: '',
        dateFromEnd: '',
        dateTo: '',
      },
      isModalBusy: false,
      isFreeMode: false,
    };
  },
  validations: {
    copyPeriod: {
      dateFrom: { required, notSameAsDateTo: not(sameAs('dateTo')), isPeriodsEqual },
      dateFromEnd: { required, isDateFromEndBiggerFrom },
      dateTo: { required, isNotPeriodToInPeriodFrom },
    },
    dateToEnd: { required, isDateToEndBiggerTo },
  },
  computed: {
    minDateFromEnd() {
      if (!this.copyPeriod.dateFrom) return undefined;
      const dateFrom = new Date(this.copyPeriod.dateFrom);
      dateFrom.setDate(dateFrom.getDate() + 1);
      return dateFrom;
    },
    minDateEndEnd() {
      if (!this.copyPeriod.dateTo) return undefined;
      const dateTo = new Date(this.copyPeriod.dateTo);
      dateTo.setDate(dateTo.getDate() + 1);
      return dateTo;
    },
    dateToEnd() {
      if (!(this.copyPeriod.dateFrom && this.copyPeriod.dateTo && this.copyPeriod.dateFromEnd)) return '';
      const difference = new Date(this.copyPeriod.dateFromEnd).getTime() - new Date(this.copyPeriod.dateFrom).getTime();
      const totalDays = Math.ceil(difference / (1000 * 3600 * 24)); // get period days count
      if (totalDays < 1) return ''; // for reversed incorrect period and period of 1 day
      const dateToEnd = new Date(this.copyPeriod.dateTo);
      dateToEnd.setDate(dateToEnd.getDate() + totalDays);
      return dateToEnd.toISOString().slice(0, 10);
    },
  },
  watch: {
    'copyPeriod.dateFrom'(newValue) {
      if (!newValue || this.isFreeMode) return;
      this.copyPeriod.dateFromEnd = this.setEndOfPeriod(newValue);
    },
    isFreeMode(newValue) {
      if (!newValue && this.copyPeriod.dateFrom && this.copyPeriod.dateTo) {
        this.copyPeriod.dateFromEnd = this.setEndOfPeriod(this.copyPeriod.dateFrom);
      }
    },
  },
  methods: {
    clearData() {
      this.copyPeriod.dateFrom = '';
      this.copyPeriod.dateTo = '';
      this.copyPeriod.dateFromEnd = '';
      this.isFreeMode = '';
    },

    setEndOfPeriod(dateStart) {
      if (!dateStart || this.isFreeMode) return;
      const to = new Date(dateStart);
      return new Date(to.getFullYear(), to.getMonth(), to.getDate() + 7).toISOString().slice(0, 10);
    },

    datesToDisabled(ymd, date) {
      // disable selected period From
      const ymdDate = new Date(ymd); // use converted to avoid timezone shifts
      const dateFrom = new Date(this.copyPeriod.dateFrom);
      const dateFromEnd = new Date(this.copyPeriod.dateFromEnd);
      return dateFrom <= ymdDate && ymdDate <= dateFromEnd;
    },

    async showModalCopyPeriodConfirm() {
      const body = this.$createElement('p', {
        domProps: {
          innerHTML: `${this.$i18n.t('broadcast.confirmCopyPeriod')} <span class="text-danger">${convertDate(this.copyPeriod.dateFrom)} - 
          ${convertDate(this.copyPeriod.dateFromEnd)} -> ${convertDate(this.copyPeriod.dateTo)} - ${convertDate(this.dateToEnd)}</span> ?`,
        },
      });
      const modalResult = await this.$bvModal
        .msgBoxConfirm(body, {
          title: this.$i18n.t('broadcast.copyPeriod'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'copy-period-confirm-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      this.postCopyPeriod();
    },

    async showModalCopyPeriodConfirmRewrite() {
      const modalResult = await this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('broadcast.confirmCopyDayRewrite')}`, {
          title: this.$i18n.t('broadcast.existData'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'copy-period-confirm-rewrite-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      this.postCopyPeriod(true);
    },

    async postCopyPeriod(rewrite_period) {
      this.isModalBusy = true;
      await this.$store.dispatch('POST_BROADCAST_COPY_WEEK', {
        data: {
          channel: this.channel.id,
        },
        date: {
          date_from_start_at: this.copyPeriod.dateFrom,
          date_from_end_at: this.copyPeriod.dateFromEnd,
          date_to_start_at: this.copyPeriod.dateTo,
          date_to_end_at: this.dateToEnd,
          force: rewrite_period,
        },
        handler: (res) => {
          const filledRes = res.data.data?.filled;
          if (filledRes?.spots > 0) {
            this.$notify({
              type: 'warning',
              duration: 4000,
              title: this.$i18n.t('alert.cantCopyInDayWithSpots'),
            });
          } else if (filledRes?.spots === 0 && (filledRes?.blocks > 0 || filledRes?.program_releases > 0)) {
            this.showModalCopyPeriodConfirmRewrite();
          } else {
            this.$notify({
              type: 'success',
              duration: 2000,
              title: this.$i18n.t('alert.copiedPeriod'),
              text: `<p class="mb-0 font-weight-bold">${this.$i18n.t('alert.copied')}: </p>
                    ${this.$i18n.t('alert.programReleasesGC')}: ${res.data.data?.copied?.program_releases}<br/>
                    ${this.$i18n.t('alert.blocksGC')}: ${res.data.data?.copied?.blocks}<br/>
                    <p class="mt-1 mb-0 font-weight-bold">${this.$i18n.t('alert.notCopied')}: </p>
                    ${this.$i18n.t('alert.programReleasesGC')}: ${res.data.data?.not_copied?.program_releases}<br/>
                    ${this.$i18n.t('alert.blocksGC')}: ${res.data.data?.not_copied?.blocks}`,
            });
            this.$bvModal.hide('copy-period-modal');
            this.$emit('periodCopied');
            this.clearData();
          }
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },
  },
};
</script>
